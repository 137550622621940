import React, { Component } from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'
import App from './App'
import Home from './home/home'
import Agreement from './Agreement/agreement'

class IRouter extends Component {
    render() {
        return (
            <HashRouter>
                <App>
                    <Switch>
                        <Route path="/agreement" component={Agreement} />
                        <Route path="/" component={Home} />
                    </Switch>
                </App>
            </HashRouter>
        )
    }
}
export default IRouter