import React, { Component } from 'react'
import { Flex } from 'antd-mobile'
import './index.scss'

export default class index extends Component {
    render() {
        return (
            <div className='customer-wrap' id="anchor-cutomer">
                <img alt="" style={{ width: "83px", height: "44px", margin: "15px 0 15px 0" }} src="./assets/title_customers.png" />
                <Flex style={{ marginBottom: '6px' }}>
                    <Flex.Item><img alt="" className="customer-logo-size" src="./assets/logo_zglt.png" /></Flex.Item>
                    <Flex.Item><img alt="" className="customer-logo-size" src="./assets/logo_zgdx.png" /></Flex.Item>
                </Flex>
                <Flex style={{ marginBottom: '6px' }}>
                    <Flex.Item><img alt="" className="customer-logo-size" src="./assets/logo_hw.png" /></Flex.Item>
                    <Flex.Item><img alt="" className="customer-logo-size" src="./assets/logo_jdzly.png" /></Flex.Item>
                </Flex>
                <Flex style={{ marginBottom: '6px' }}>
                    <Flex.Item><img alt="" className="customer-logo-size" src="./assets/logo_zgcsz.png" /></Flex.Item>
                    <Flex.Item><img alt="" className="customer-logo-size" src="./assets/logo_xlw.png" /></Flex.Item>
                </Flex>
                <Flex style={{ marginBottom: '6px' }}>
                    <Flex.Item><img alt="" className="customer-logo-size" src="./assets/logo_gxzc.png" /></Flex.Item>
                    <Flex.Item><img alt="" className="customer-logo-size" src="./assets/logo_cyrj.png" /></Flex.Item>
                </Flex>
                <div style={{ height: "40px" }}></div>
            </div>
        )
    }
}
