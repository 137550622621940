import React, { Component } from 'react'
import "./index.scss";
import { Flex } from 'antd-mobile';

class TalentGrid extends Component {
    render() {
        return (
            <div className='talent-grid-wrap' id="anchor-talent">
                <img alt="" style={{ width: "70px", height: "39px", margin: "15px 0 17px 0" }} src="./assets/title_talents.png" />
                <Flex>
                    <Flex.Item>
                        <div className="talent-grid-item">
                            <img style={{ width: "72px", height: "72px" }} alt="example" src="./assets/talent_scx.png" />
                            <div className="talent-info-name">沈昌祥</div>
                            <div className="talent-info-extends">中国工程院院士</div>
                            <div className="talent-info-extends">国家集成电路产业发展咨询委员</div>
                        </div>
                    </Flex.Item>
                    <Flex.Item>
                        <div className="talent-grid-item">
                            <img style={{ width: "72px", height: "72px" }} alt="example" src="./assets/talent_zbl.png" />
                            <div className="talent-info-name">张伯礼</div>
                            <div className="talent-info-extends">中国工程院院士</div>
                            <div className="talent-info-extends">天津中医药大学校长</div>
                        </div>
                    </Flex.Item>
                </Flex>
                <Flex>
                    <Flex.Item>
                        <div className="talent-grid-item">
                            <img style={{ width: "72px", height: "72px" }} alt="example" src="./assets/talent_yz.png" />
                            <div className="talent-info-name">尤政</div>
                            <div className="talent-info-extends">中国工程院院士</div>
                            <div className="talent-info-extends">清华大学副校长</div>
                        </div>
                    </Flex.Item>
                    <Flex.Item>
                        <div className="talent-grid-item">
                            <img style={{ width: "72px", height: "72px" }} alt="example" src="./assets/talent_fjc.png" />
                            <div className="talent-info-name">房建成</div>
                            <div className="talent-info-extends">中国科学院院士</div>
                            <div className="talent-info-extends">北京航空航天大学常务副校长</div>
                        </div>
                    </Flex.Item>
                </Flex>
                <Flex>
                    <Flex.Item>
                        <div className="talent-grid-item">
                            <img style={{ width: "72px", height: "72px" }} alt="example" src="./assets/talent_lm.png" />
                            <div className="talent-info-name">刘明</div>
                            <div className="talent-info-extends">中国科学院院士</div>
                            <div className="talent-info-extends">中国科学院微电子研究所研究员</div>
                        </div>
                    </Flex.Item>
                    <Flex.Item>
                        <div className="talent-grid-item">
                            <img style={{ width: "72px", height: "72px" }} alt="example" src="./assets/talent_sl.png" />
                            <div className="talent-info-name">宋梁</div>
                            <div className="talent-info-extends">加拿大国家工程院院士</div>
                            <div className="talent-info-extends">复旦大学特聘教授</div>
                        </div>
                    </Flex.Item>
                </Flex>
                <div style={{ height: "22px" }}></div>
            </div>
        )
    }
}
export default TalentGrid;
