export const reg_password = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;

const reg_email = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/

const reg_mobile = /^[1][3,4,5,7,8][0-9]{9}$/;

// 验证密码
export function validate_password(value) {
    return reg_password.test(value);
}

// 验证邮箱
export function validate_email(value) {
    return reg_email.test(value);
}

// 验证手机号
export function validate_mobile(value) {
    return reg_mobile.test(value);
}