import React, { Component } from 'react'

class bigpic extends Component {
    render() {
        return (
            <div>
                <img width='100%' alt="" src="./assets/head_pic.png" />
            </div>
        )
    }
}
export default bigpic
