import React, { Component } from 'react'
import "./index.scss";
import { Flex } from 'antd-mobile';

class index extends Component {
    render() {
        return (
            <div className='service-wrap' id="anchor-service">
                <Flex>
                    <Flex.Item>
                        <img alt='' style={{ width: "71px", height: "44px", margin: "15px 0 15px 0" }} src="./assets/title_services.png" />
                    </Flex.Item>
                </Flex>
                <Flex>
                    <Flex.Item>
                        <div className="service-item">
                            <img style={{ width: "32px", height: "37px" }} alt="example" src="./assets/icon_zjzx.png" />
                            <div className="service-info">专家咨询</div>
                            <div className="service-info-extends">专家提供一对一咨询服务</div>
                        </div>
                    </Flex.Item>
                    <Flex.Item>
                        <div className="service-item">
                            <img style={{ width: "35px", height: "35px" }} alt="example" src="./assets/icon_zjcx.png" />
                            <div className="service-info">专家出席</div>
                            <div className="service-info-extends">专家出席会议、论坛、讲座等</div>
                        </div>
                    </Flex.Item>
                </Flex>
                <Flex>
                    <Flex.Item>
                        <div className="service-item">
                            <img style={{ width: "26px", height: "33px" }} alt="example" src="./assets/icon_rcps.png" />


                            <div className="service-info">人才评审</div>
                            <div className="service-info-extends">高层次人才评审、职称评审等</div>
                        </div>
                    </Flex.Item>
                    <Flex.Item>
                        <div className="service-item">
                            <img style={{ width: "36px", height: "37px" }} alt="example" src="./assets/icon_ktps.png" />


                            <div className="service-info">课题评审</div>
                            <div className="service-info-extends">立项评审、结题评审、成果评审</div>
                        </div>
                    </Flex.Item>
                </Flex>
                <Flex>
                    <Flex.Item>
                        <div className="service-item">
                            <img style={{ width: "45px", height: "38px" }} alt="example" src="./assets/icon_xmhz.png" />


                            <div className="service-info">项目合作</div>
                            <div className="service-info-extends">跨行业、地域、机构的产学研合作</div>
                        </div>
                    </Flex.Item>
                    <Flex.Item>
                        <div className="service-item">
                            <img style={{ width: "28px", height: "36px" }} alt="example" src="./assets/icon_ycyz.png" />


                            <div className="service-info">引才引智</div>
                            <div className="service-info-extends">按需精准匹配高层次专家及团队</div>
                        </div>
                    </Flex.Item>
                </Flex>

                <div style={{ height: "40px" }}></div>
            </div>


        )
    }
}
export default index;
