import React, { Component } from 'react'
import BigPic from '../components/BigPic/bigpic'
import TabMenu from '../components/TabMenu/tabmenu'
import Requirement from '../components/Requirement/requirement'
import './home.scss'

class home extends Component {
    render() {
        return (
            <div className="home-wrap">
                <BigPic />
                <div className="big-title">
                    智库简介
                </div>
                <TabMenu />
                <Requirement />
            </div>
        )
    }
}

export default home
