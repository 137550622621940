import React, { Component } from 'react'
import { Flex } from 'antd-mobile'
import './index.scss'
class index extends Component {
    render() {
        return (
            <div className='advantage-wrap' id="anchor-advantage">
                <img alt="" style={{ width: "83px", height: "44px", margin: "15px 0 17px 0" }} src="./assets/title_advantages.png" />
                <Flex>
                    <Flex.Item>
                        <div className="advantage-item">
                            <div className="advantage-item-left">
                                <div className="number">
                                    1
                                </div>
                            </div>
                            <div className="advantage-item-right">
                                <div className="text">
                                    <div className="advantage-item-right-line1">百位院士领衔的高层次专家库</div>
                                    <div className="advantage-item-right-line2">精准匹配高层次专家，快速锁定两院院士，国家级、省部级专家行程。</div>
                                </div>
                            </div>
                        </div>
                    </Flex.Item>
                </Flex>
                <Flex>
                    <Flex.Item>

                        <div className="advantage-item">
                            <div className="advantage-item-left">
                                <div className="number">
                                    2
                                </div>
                            </div>
                            <div className="advantage-item-right">
                                <div className="text">
                                    <div className="advantage-item-right-line1">定制化专家服务解决方案</div>
                                    <div className="advantage-item-right-line2">灵活对接政、产、学、研各方面高端资源，为不同主体提供定制化解决方案。</div>
                                </div>
                            </div>
                        </div>
                    </Flex.Item>
                </Flex>

                <Flex>
                    <Flex.Item>
                        <div className="advantage-item">
                            <div className="advantage-item-left">
                                <div className="number">
                                    3
                                </div>
                            </div>
                            <div className="advantage-item-right">
                                <div className="text">
                                    <div className="advantage-item-right-line1">专家助手全流程一对一服务</div>
                                    <div className="advantage-item-right-line2">经验丰富的专家助手全流程一对一跟踪服务，确保专家服务高质量、高效完成。</div>
                                </div>
                            </div>
                        </div>
                    </Flex.Item>
                </Flex>
                <div style={{ height: "40px" }}></div>
            </div >
        )
    }
}
export default index;
