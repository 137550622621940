import React, { Component } from 'react'
import { Button, InputItem, Toast, TextareaItem, Modal } from 'antd-mobile'
import { createForm } from 'rc-form';
import Code from '../Code/code'
// 路由
import { withRouter } from 'react-router-dom'
// API
import { RequirementAdd } from '../../api/requirement'
// 加载
import display_loading from "../../utils/loading"
import './requirement.scss'

class requirement extends Component {
    state = {
        mobile: '',
        agree: false,
        modal: false
    }
    onCloseModal = () => {
        this.setState({
            modal: false,
        });
    }
    onChange = (value) => {
        this.setState({
            mobile: value
        });
    }
    checkboxOnclick = (e) => {
        console.log(e.target.checked);
        this.setState({
            agree: e.target.checked
        });
    }
    validateCompany = (rule, value, callback) => {
        if (value && value.length > 3 && value.length < 31) {
            callback();
        } else {
            callback(new Error('至少4个字，至多30个字'));
        }
    }
    validateName = (rule, value, callback) => {
        if (value && value.length > 1 && value.length < 11) {
            callback();
        } else {
            callback(new Error('至少2个字，至多15个字！'));
        }
    }
    validateMobile = (rule, value, callback) => {
        this.setState({
            mobile: value
        });
        if (value && value.length === 13) {
            callback();
        } else {
            callback(new Error('11位！'));
        }
    }
    validateCode = (rule, value, callback) => {
        if (value && value.length === 4) {
            callback();
        } else {
            callback(new Error('4位数字！'));
        }
    }

    onSubmit = () => {

        if (!this.state.agree) {
            Toast.info("请先同意服务协议！")
            return
        }

        this.props.form.validateFields({ force: true }, (error) => {
            if (!error) {
                const value = this.props.form.getFieldsValue();
                let requestData = {
                    mobile: this.state.mobile.replace(/\s/g, ''),
                    company: value.company,
                    name: value.name,
                    vcode: value.vcode,
                    summary: value.summary
                }
                console.log(requestData);
                display_loading(true);
                RequirementAdd(requestData).then(response => {
                    display_loading(false);
                    let result = response.data;
                    if (result.errorCode === 0) {
                        this.setState({
                            modal: true
                        })
                        this.props.form.resetFields();
                        return;
                    }
                    Toast.fail(result.msg, 1);
                }).catch(error => {
                    display_loading(false);
                })
            } else {
                Toast.info('表单填写验证失败！');
            }
        });
    }
    onClickAgreement = () => {
        this.props.history.push('/agreement')
    }
    render() {
        const { getFieldProps, getFieldError } = this.props.form;
        return (
            <div className="requirement-wrap">
                <div className="requirement-title">
                    极速找专家
                </div>
                <div className="requirement-title-tips">
                    隐私保护：您所填信息仅专家及专家助手可见，请放心填写。
                </div>
                <div className="requirement-area-title">
                    需求简介
                </div>

                {/* <textarea rows="4" {...getFieldProps('summary')} placeholder="请介绍您的活动主题、主办方、活动时间、活动地点、拟邀专家、预算范围等，以及需要专家配合哪些工作。"
                    /> */}
                <TextareaItem
                    className="requirement-description"
                    {...getFieldProps('summary', {
                        initialValue: '',
                    })}
                    clear
                    rows={4}
                    style={{
                        fontSize: "16px",
                    }}
                    placeholder="请介绍您的活动主题、主办方、活动时间、活动地点、拟邀专家、预算范围等，以及需要专家配合哪些工作。"
                ></TextareaItem>

                <div className="requirement-area-title">
                    需求方信息
                </div>

                <div className="requirement-item">

                    <div className="requirement-item-title">
                        单位名称*
                    </div>
                    {/* <input className="requirement-item-input" placeholder="请填写完整单位名称" /> */}

                    <InputItem
                        className="requirement-item-input"
                        {...getFieldProps('company', {
                            // initialValue: 'little ant',
                            rules: [
                                { required: true, message: '请填写完整单位名称' },
                                { validator: this.validateCompany },
                            ],
                        })}
                        clear
                        error={!!getFieldError('company')}
                        onErrorClick={() => {
                            Toast.info(getFieldError('company').join('、'));
                        }}
                        style={{
                            fontSize: "16px",
                        }}
                        placeholder="请填写完整单位名称"
                    ></InputItem>
                </div>
                <div className="requirement-item">
                    <div className="requirement-item-title">
                        联系人姓名*
                    </div>
                    {/* <input className="requirement-item-input" placeholder="请填写真实姓名" /> */}
                    <InputItem
                        className="requirement-item-input"
                        {...getFieldProps('name', {
                            // initialValue: 'little ant',
                            rules: [
                                { required: true, message: '请填写真实姓名' },
                                { validator: this.validateName },
                            ],
                        })}
                        clear
                        error={!!getFieldError('name')}
                        onErrorClick={() => {
                            Toast.info(getFieldError('name').join('、'));
                        }}
                        style={{
                            fontSize: "16px",
                        }}
                        placeholder="请填写真实姓名"
                    ></InputItem>
                </div>
                <div className="requirement-item">
                    <div className="requirement-item-title">
                        手机号码*
                    </div>
                    {/* <input className="requirement-item-input" placeholder="请填写常用手机号码" value={username} onChange={this.inputChangeUsername} maxLength="11" required pattern="[0-9]" /> */}
                    <InputItem
                        {...getFieldProps('mobile', {
                            // initialValue: 'little ant',
                            rules: [
                                { required: true, message: '请填写常用手机号码' },
                                { validator: this.validateMobile },
                            ],
                        })}
                        className="requirement-item-input"
                        type="phone"
                        placeholder="请填写常用手机号码"
                        error={!!getFieldError('mobile')}
                        onErrorClick={() => {
                            Toast.info(getFieldError('mobile').join('、'));
                        }}
                        style={{
                            fontSize: "16px",
                        }}
                    ></InputItem>
                </div>
                <div className="requirement-item">
                    <div className="requirement-item-title">
                        手机号验证*
                    </div>
                    <div className="requirement-item-right" >
                        {/* <input placeholder="请输入验证码" /> */}
                        <InputItem
                            className="requirement-item-right-input"
                            {...getFieldProps('vcode', {
                                // initialValue: 'little ant',
                                rules: [
                                    { required: true, message: '请输入验证码' },
                                    { validator: this.validateCode },
                                ],
                            })}
                            type="number"
                            placeholder="验证码"
                            error={!!getFieldError('vcode')}
                            onErrorClick={() => {
                                Toast.info(getFieldError('vcode').join('、'));
                            }}
                            style={{
                                fontSize: "16px",
                            }}
                        ></InputItem>
                        <Code mobile={this.state.mobile && this.state.mobile.replace(/\s/g, '')} />
                    </div>
                </div>
                <div className="requirement-agreement">
                    <input className="checkbox" type="checkbox" name="agree" value="" onClick={this.checkboxOnclick} />
                    <div className="requirement-agreement-agree-text">
                        我已同意
                    </div><div className="requirement-agreement-detail" onClick={this.onClickAgreement}>
                        《创壹智库用户服务协议》
                    </div>
                </div>
                <Button type="primary" className="requirement-btn-submit" onClick={this.onSubmit}>提交需求</Button>
                <Modal
                    visible={this.state.modal}
                    transparent
                    maskClosable={false}
                    onClose={this.onCloseModal}
                    title="提交成功"
                    footer={[{ text: 'Ok', onPress: () => { this.onCloseModal() } }]}
                    wrapProps={{ onTouchStart: this.onWrapTouchStart }}
                >
                    <div>
                        感谢您的需求提交<br />
                        我们会在第一时间与您联系<br />
                        请保持手机畅通<br />
                    </div>
                </Modal>
            </div>
        )
    }
}
export default createForm()(withRouter(requirement))
