import React, { Component } from "react"

// antd
import { Button, Toast } from 'antd-mobile'

// API
import { GetCode } from '../../api/requirement'

// 验证邮箱
import { validate_mobile } from "../../utils/validate"

// 定时器
let timer = null;

class Code extends Component {

    //属性初始化器
    state = {
        mobile: this.props.mobile,
        button_text: "获取验证码",
        button_loading: false,
        button_disabled: false,
    }


    static getDerivedStateFromProps(props, state) {
        if (props.mobile !== state.mobile) {
            return {
                mobile: props.mobile
            }
        }
        return null;
    }

    // 获取验证码
    getCode = () => {
        const mobile = this.state.mobile;
        if (!mobile) {
            Toast.info('手机号不能为空！！', 1);
            return false;
        }

        if (!validate_mobile(mobile)) {
            Toast.info('手机号码格式不正确', 1);
            return false;
        }

        this.setState({
            button_loading: true,
            code_button_text: "发送中"
        })

        const requestData = {
            mobile: mobile,
            type: 0
        }
        GetCode(requestData).then(response => {
            let result = response.data;
            if (result.errorCode === 0) {
                // 执行倒计时
                Toast.success(result.msg, 1);
                this.countDown();
                return true;
            } else {
                Toast.success(result.msg, 1);
                this.setState({
                    button_loading: false,
                    button_text: "重新发送"
                })
            }
        }).catch(error => {
            this.setState({
                button_loading: false,
                button_text: "重新发送"
            })
        })
    }

    componentWillUnmount() {
        clearInterval(timer);
    }

    // 倒计时
    countDown = () => {
        let sec = 60;
        this.setState({
            button_loading: false,
            button_disabled: true,
            button_text: `${sec}S`
        })

        timer = setInterval(() => {
            sec--;
            if (sec <= 0) {
                this.setState({
                    button_disabled: false,
                    button_text: "重新获取"
                })
                clearInterval(timer);
                return false;
            }
            this.setState({
                button_text: `${sec}S`
            })
        }, 1000)

    }
    render() {
        const { button_disabled, button_loading, button_text } = this.state;
        return <Button style={{ fontSize: "16px", borderRadius: "7px", height: "44px", lineHeight: "40px" }} inline type="primary" disabled={button_disabled} loading={button_loading} onClick={this.getCode} >{button_text}</Button>
    }
}

export default Code