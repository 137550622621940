import React, { Component } from 'react'
import { Tabs } from 'antd-mobile';
import TalentGrid from '../TalentGrid/index';
import ServiceGrid from '../ServiceGrid/index';
import Solution from '../Solution/index';
import Advantage from '../Advantage/index';
import Customer from '../Customer/index';
import Introduction from '../Introduction/index';
import './tabmenu.scss'

class tab extends Component {
    render() {
        const tabs = [
            { title: '智库专家' },
            { title: '专家服务' },
            { title: '解决方案' },
            { title: '智库优势' },
            { title: '服务客户' },
            { title: '关于我们' },
        ];

        return (
            <div>
                <Tabs
                    style={{ backgroundColor: "#0065F8" }}
                    tabs={tabs}
                    initialPage={0}
                    onChange={(tab, index) => { console.log('onChange', index, tab); }}
                    onTabClick={(tab, index) => { console.log('onTabClick', index, tab); }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                        <TalentGrid />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                        <ServiceGrid />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                        <Solution />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                        <Advantage />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                        <Customer />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                        <Introduction />
                    </div>
                </Tabs>
            </div>
        )
    }
}
export default tab